export default function Thumbnail() {
  return (
    <>
      <div className="lg:grid lg:place-content-center  ">
        <div className="lg:w-[1195px] mb-24 mt-16 ">
          <div className="md:flex md:justify-between md:items-center ">
            <div>
              <h1 className="px-4 font-Rubik text-[#82BE41] text-[28px] leading-none sm:text-[38px] font-bold">Video Pemotongan</h1>
              <h1 className="px-4 font-Rubik text-[#787878] text-[28px] leading-1 sm:text-[38px] font-normal">Qurban Abon</h1>
              <p className="px-4 mt-4 text-[#787878] text-[15px] font-normal w-[377px]">Yuk, Lihat proses pemotongan qurban terbaikmu dan Kami maksimalkan proses pengolahan Abon Kita Qurban IZI dengan higienis.</p>
              <a href="https://www.youtube.com/playlist?list=PLTrE-RBJl_FS2ycfFP21ka-ohKZGZLxny" target="_blank" rel="noopener noreferrer">
                <button className="px-4 py-3 mt-6 ml-4 bg-[#84db00] hover:bg-[#6EB700] transition-300 text-white text-base font-Rubik rounded-[15px]">Lihat Video Selengkapnya</button>
              </a>
            </div>
            <a href="https://youtu.be/-yXzlO4kjIA?si=HrJ-ncaqwtg4L9aj" target="_blank" rel="noopener noreferrer">
              <iframe src="https://www.youtube.com/embed/-yXzlO4kjIA?si=avzJm1zg4zVL0DGX" title="video" className="sm:w-[542px] w-[350px] h-[210px] sm:h-[295px] border-0 rounded-xl mt-10 sm:mt-6 ml-4  drop-shadow-md" style={{ pointerEvents: "none" }} />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
