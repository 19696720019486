import Footer from "../Components/Footer";
export default function PageNotFound() {
  return (
    <>
      <div className="w-full h-[15px] bg-gradient-to-r from-[#59b221] to-[#98ef03]"></div>
      <div className="grid place-content-center py-[50%] sm:py-[15%] font-Rubik">
        <h1 className="text-center text-[50px] sm:text-[100px] font-bold text-[#82BE41] leading-none">404</h1>
        <p className=" font-normal text-[15px] sm:text-[20px] text-[#787878]">Yah, Data nya tidak di temukan.</p>
      </div>
     
      <Footer />
    </>
  );
}
