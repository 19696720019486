import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import id from "moment/locale/id";

export default function HistoryStatus(props) {
  const urlDetail = `https://trackingapi.inisiatif.id/api/v1/tracking/${props.id}/event`;

  const [detailFinish, setDetailFinish] = useState([]);

  useEffect(() => {
    axios.get(urlDetail).then((res) => {
      setDetailFinish(res.data);
    });
  }, [urlDetail]);

  const dateFromat = (param) => {
    if (!param) {
      return "-";
    }
    return moment(param).locale("id", id).format("DD MMMM YYYY");
  };

  const steps = [
    { type: "CONFIRMED", description: "Konfirmasi", date: null },
    { type: "VERIFIED", description: "Verifikasi", date: null },
    { type: "CUTED", description: "Pemotongan", date: null },
    { type: "FINISHED", description: "Penyaluran", date: null },
  ];

  const steppers = steps.map((step) => {
    const eventIndex = detailFinish.findLastIndex((event) => event.type === step.type);
    if (eventIndex < 0) {
      return step;
    } else {
      const event = detailFinish[eventIndex];

      return { ...step, date: event.date, description: event.description };
    }
  });

  return (
    <>
      <div className="grid place-content-start sm:place-content-center">
        <div className="grid grid-cols-1 sm:grid-cols-4 py-4 px-0 sm:w-[1000px] sm:px-16 font-Rubik text-[#787878]">
          {steppers.map((details, i, active) => (
            <div className="flex flex-row sm:flex-col text-left sm:text-center py-4 sm:py-0" key={details.type}>
              <div className="relative flex items-center justify-center w-[28px] h-[28px] bg-[#84db00] rounded-full mx-0 sm:mx-auto mb-4">
                {i !== 3 && <div className="h-[90px] sm:h-1 w-[4px] sm:w-[230px] bg-[#84db00] absolute top-2 sm:top-3 sm:left-2 "></div>}
                <h1 className="text-white z-10">{i + 1}</h1>
              </div>
              <div className="px-8">
                <h3 className="font-bold mb-2 text-[15px]">{details.description}</h3>
                <p className="text-light text-base">{dateFromat(details.date)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
