export default function LaporkanKesalahan() {
  return (
    <>
      <div className="grid place-items-center sm:mt-16 mt-4">
        <div className="text-[#787878] font-Rubik font-medium text-[15px] ">
          <h3>Ada Kesalahan Data Transaksi ?</h3>
        </div>
        <p className="text-[#787878] font-Rubik font-normal text-base px-2 sm:text-center text-center">Laporkan kesalahan transaksimu, kami akan segera membantu.</p>
        <div className="my-[50px]">
          <a href="mailto:qurban@izi.or.id">
            <button className="w-[234px] h-[50px] bg-[#84db00] hover:bg-[#6EB700] transition duration-300 text-white font-Rubik rounded-[15px] drop-shadow-md hover:drop-shadow-xl">Laporkan Kesalahan</button>
          </a>
        </div>
      </div>
    </>
  );
}
