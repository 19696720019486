import { Route, Routes } from "react-router-dom";
import AllDetailTransaksi from "./Pages/AllDetailTransaksi";
import Home from "./Pages/Home";
import PageNotFound from "./Pages/PageNotFound";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/track/:id" element={<AllDetailTransaksi />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
