import { Link } from "react-router-dom";
import icon_arrw from "../images/arrow.svg";

export default function ButtonBack() {
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <div className="grid place-content-center mb-28 mt-8">
      <button onClick={scrollToTop}>
        <Link to="/">
          <div className="flex gap-2">
            <img src={icon_arrw} alt="icon_arrow" />
            <span className="font-Rubik font-medium text-[#82BE41]">Back To Home</span>
          </div>
        </Link>
      </button>
    </div>
  );
}
