import axios from "axios";
import { useEffect, useState } from "react";

export default function DownloadLaporan(props) {
  const urlDetail = `https://trackingapi.inisiatif.id/api/v1/tracking/${props.id}/event`;
  const [name, setName] = useState("");
  const [downloadurl, setDownloadurl] = useState("");

  useEffect(() => {
    axios.get(urlDetail).then((res) => {
      const finishEvents = res.data.filter((x) => x.type === "FINISHED");
      if (finishEvents.length) {
        const lastEvent = finishEvents[finishEvents.length - 1];
        setName(lastEvent.meta);
        setDownloadurl(lastEvent.meta);
      }
    });
  }, [urlDetail]);

  if (!downloadurl) {
    return <></>;
  }

  return (
    <>
      <div className="mt-4">
        <h1 className="font-Rubik font-medium text-[#787878] text-[16px] ">Laporan Qurban </h1>
        <div className="bg-[#82BE41] h-[3px] w-[80px] rounded-[5px] mt-2"></div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 mt-8 font-Rubik text-base sm:text-[15px] font-semibold text-[#787878]">
        <div className="text-center">
          <div className="bg-[#F5F5F5] py-1 font-light">
            <h3>File Laporan</h3>
          </div>
          {name.map((nama) => (
            <h3 key={nama.id} className="my-8 px-8 max-w mx-auto">
              Laporan Penyaluran a.n {nama.name.replace(".pdf", "")}
            </h3>
          ))}
        </div>
        <div className="text-center">
          <div className="bg-[#F5F5F5] py-1 font-light">
            <h3>Aksi</h3>
          </div>
          <div className="text-center mt-6">
            {downloadurl.map((url) => (
              <div key={url.id}>
                <a href={url.full_url} target="_blank" rel="noopener noreferrer">
                  <button className="py-3 mb-2 px-16 bg-[#84db00] hover:bg-[#6EB700] transition duration-300 text-white font-Rubik rounded-[15px]">Download</button>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
