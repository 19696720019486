import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import Icon_check from "../images/checklist.svg";
import id from "moment/locale/id";

export default function ResultSummary({ noid }) {
  const url = `https://trackingapi.inisiatif.id/api/v1/tracking/${noid}`;
  const urlDetail = `https://trackingapi.inisiatif.id/api/v1/tracking/${noid}/event`;

  const [dataTransaksi, setdataTransaksi] = useState({ details: [{}] });
  const [detail, setDetail] = useState([]);

  const events = [
    { type: "CONFIRMED", description: null },
    { type: "VERIFIED", description: null },
    { type: "CUTED", description: null },
    { type: "FINISHED", description: null },
  ];

  useEffect(() => {
    if (noid) {
      axios.get(url).then((res) => {
        setdataTransaksi(res.data.meta);
      });
      axios.get(urlDetail).then((res) => {
        setDetail(res.data);
      });
    }
  }, [noid]);

  const dateFromat = (param) => {
    if (!param) {
      return "-";
    }
    return moment(param).locale("id", id).format("DD-MMMM-YYYY");
  };

  const rupiahFormat = (param) => {
    if (!param) {
      return "-";
    }

    if(isNaN(+param)) {
      return param;
    }
    
    const price = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      maximumSignificantDigits: 3,
    }).format(param);
    return price;
  };

  const eventType = events.map((event) => {
    const eventIndex = detail.findIndex((events) => events.type === event.type);
    if (eventIndex < 0) {
      return event;
    } else {
      const e = detail[eventIndex];
      return { ...event, description: e.description };
    }
  });

  const status = [];
  eventType.forEach((details, i, array) => {
    if (array.length - 1) {
      status.push(details.description);
    }
  });

  const lastEvent = status.filter((element) => {
    return element !== null;
  });

  return (
    <>
      <div className="grid grid-cols-2 sm:grid-cols-4 mt-8 font-Rubik text-base sm:text-[15px] font-semibold text-[#787878]">
        <div className="text-center">
          <div className="bg-[#F5F5F5] py-1 font-light">
            <h3>Nomor Transaksi</h3>
          </div>
          <h3 className="my-4 max-w-[200px] mx-auto">{dataTransaksi.identification_number}</h3>
        </div>
        <div className=" text-center">
          <div className="bg-[#F5F5F5] py-1 font-light">
            <h3>Nama Donatur</h3>
          </div>
          <h3 className="my-4 max-w-[200px] mx-auto">{dataTransaksi.name}</h3>
        </div>
        <div className=" text-center">
          <div className="bg-[#F5F5F5] py-1 font-light">
            <h3>Tanggal Transaksi</h3>
          </div>
          <h3 className="my-4 max-w-[200px] mx-auto ">{dateFromat(dataTransaksi.payment_date)}</h3>
        </div>
        <div className="text-center">
          <div className="bg-[#F5F5F5] py-1 font-light">
            <h3>Total Pembayaran</h3>
          </div>

          <h3 className="my-4 max-w-[200px] mx-auto ">{rupiahFormat(dataTransaksi.amount)}</h3>
        </div>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-4 mt-0 sm:mt-8 font-Rubik text-base sm:text-[15px] font-semibold text-[#787878]">
        <div className="col-span-1 sm:col-span-2  text-center">
          <div className="bg-[#F5F5F5] py-1 font-light">
            <h3>Produk</h3>
          </div>
          {dataTransaksi.details.map((detail, index) => (
            <h3 key={index + 1} className="my-4 max-w-[400px] mx-auto ">
              {detail.program}
            </h3>
          ))}
        </div>
        <div className="text-center">
          <div className="bg-[#F5F5F5] py-1 font-light">
            <h3>Jumlah Pembayaran</h3>
          </div>
          {dataTransaksi.details.map((detail, index) => (
            <h3 key={index + 1} className="my-4 max-w-[200px] mx-auto ">
              {rupiahFormat(detail.amount)}
            </h3>
          ))}
        </div>
        <div className="col-span-2 sm:col-span-1 text-center">
          <div className="bg-[#F5F5F5] py-1 font-light">
            <h3>Status Qurban</h3>
          </div>
          <div className="flex gap-1 justify-center">
            <h3 className="my-4 max-w-[200px] text-[#82BE41] ">{lastEvent[lastEvent.length - 1]}</h3>
            <img src={Icon_check} alt="icon_checklist" />
          </div>
        </div>
      </div>
    </>
  );
}
