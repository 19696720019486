import Thumbnail from "./Thumbnail";

export default function DataNotFound() {
  return (
    <>
      <div className="grid place-items-center sm:py-[5%] py-[20%]">
        <div className="text-center">
          <h3 className="text-[#84db00] font-Rubik font-semibold text-[20px] sm:text-[30px] ">No Data</h3>
          <h3 className="font-Rubik text-[#787878]">Silahkan masukan nomor transaksimu</h3>
        </div>
      </div>
      <Thumbnail />
    </>
  );
}
