import React from "react";
import Logo from "../images/logo_izi_putih.png";
import Vector from "../images/vector.png";
import Icon_fb from "../images/sosmed/fb.png";
import Icon_ig from "../images/sosmed/ig.png";
import Icon_twitter from "../images/sosmed/twitter.png";
import Icon_yt from "../images/sosmed/yt.png";

export default function Footer() {
  return (
    <>
      <footer className="grid place-items-center py-16 px-4 bg-white bg-gradient-to-r from-[#59b221] to-[#98ef03]">
        <div className="max-w-full">
          <div className="sm:flex sm:justify-between pt-8">
            <div>
              <img className="h-14 my-4" src={Logo} alt="Logo IZI" />
              <h4 className="text-base font-Rubik text-white font-normal">Alamat Kantor Pusat</h4>
              <p className="text-base font-Rubik text-white font-normal">
                Jl. Raya Condet No.27 G Batu Ampar <br />
                Jakarta Timur, DKI Jakarta, Indonesia 13520 - Indonesia
                <br />
                Telp : 021 8778 7325 | Fax : 021 8778 7603 | Email : salam@izi.or.id
              </p>
              <div className="flex mt-4 space-x-6 mb-8">
                <a href="https://www.facebook.com/InisiatifZakatID">
                  <img src={Icon_fb} className="h-7" alt="fb" />
                </a>
                <a href="https://www.instagram.com/inisiatifzakat/">
                  <img src={Icon_ig} className="h-7" alt="ig" />
                </a>
                <a href="https://twitter.com/inisiatifzakat">
                  <img src={Icon_twitter} className="h-7" alt="twitter" />
                </a>
                <a href="https://www.youtube.com/channel/UC3KGwC1O1DgCbySUh6mCrWA">
                  <img src={Icon_yt} className="h-7" alt="yt" />
                </a>
              </div>
            </div>
            <div>
              <img className="h-36 sm:h-44 my-8" src={Vector} alt="Vector IZI" />
            </div>
          </div>
          <div className="sm:flex sm:items-center sm:justify-between ">
            <span className="text-sm font-Rubik text-white sm:text-left sm:mr-96 font-normal">All Rights Reserved. Lembaga Amil Zakat Nasional SK Menteri Agama RI No. 423 Tahun 2015</span>
            <div>
              <span className="text-sm font-Rubik font-normal text-white sm:text-left ">Copyright © 2022, Inisiatif Zakat Indonesia.</span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
