import React from "react";
import { Link } from "react-router-dom";
import LaporkanKesalahan from "./LaporkanKesalahan";
import ResultSummary from "./ResultSummary";
import Thumbnail from "./Thumbnail";

export default function BodySearch(props) {
  const scrollToTop = () => window.scrollTo({ top: 0 });

  return (
    <>
      <div>
        <div className="grid place-items-center pt-10 px-6 sm:px-6">
          <div>
            <div>
              <h1 className="font-Rubik font-bold text-[#787878] text-[24px] sm:text-xl">Hasil Pencarian</h1>
              <div className="bg-[#82BE41] h-[4px] sm:h-[6px]  w-[160px] rounded-[5px] mt-2"></div>
            </div>
            <div className="bg-[#E6F8CC] sm:h-[40px] h-[65px] mt-8 py-[12px] rounded-[8px] lg:w-[1195px] md:w-[1000px] ">
              <p className="text-[#787878] text-base leading-1 sm:leading-none px-[20px]">Nomor transaksimu berhasil di temukan, berikut data dari transaksimu :</p>
            </div>
            <div className="mt-8 ">
              <h3 className="font-Rubik font-medium text-base text-[#787878] ">Ringkasan Transaksi</h3>
              <div className="h-[0.5px] bg-[#b8b8b8] mt-4"></div>
              <div>
                <ResultSummary noid={props.noid} />
              </div>
              <div className="grid place-content-center">
                <button className="py-[10px] px-[30px] my-8 border-[1.5px] border-[#84db00] hover:bg-[#84db00] transition duration-300 text-[#82BE41] hover:text-white font-Rubik rounded-[10px] font-medium text-base">
                  <Link onClick={scrollToTop} to={`/track/${props.noid}`}>
                    Lihat Detail
                  </Link>
                </button>
              </div>
              <div className="h-[0.5px] bg-[#b8b8b8] mt-4"></div>
            </div>
          </div>
        </div>
      </div>
      <LaporkanKesalahan />
      <Thumbnail />
    </>
  );
}
