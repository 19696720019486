import React, { useState } from "react";
import Vector_Hero1 from "../images/vector_hero1.png";
import Vector_Hero2 from "../images/vector_hero2.png";
import Logo from "../images/logo_izi_putih.png";
import LogoZP from "../images/logo_zakat_white.png";

export default function Hero(props) {
  const [field, setfield] = useState("");
  const scrollToSection = () => window.scrollTo({ top: 700, behavior: "smooth" });

  const SubmitButton = () => {
    scrollToSection();
    props.onSearch(field);
  };

  return (
    <>
      <div>
        <div className="min-h-[580px] bg-gradient-to-t from-[#4b9c19] to-[#98ef03]">
          <div className="flex gap-8 px-6 sm:px-16 pt-6 mb-2">
            <img src={Logo} className="h-[35px] sm:h-[45px]  mb-2" alt="logo_izi_putih" />
            <img src={LogoZP} className="h-[35px] sm:h-[45px]" alt="logo_zakat_putih" />
          </div>
          <div className="grid place-items-center">
            <h2 className="font-Rubik text-center text-white sm:text-[40px] text-[30px] font-bold mt-4 sm:mt-0 mb-4 sm:mb-0 ">
              Selamat Datang
              <br />
              Donatur LAZNAS IZI
            </h2>
            <div className="sm:w-[680px] h-[150px] sm:h-[100px] bg-white rounded-[20px] drop-shadow-xl hover:drop-shadow-2xl m-4 sm:m-4">
              <div className="sm:flex sm:justify-between px-[30px] py-[25px]">
                <input
                  value={field}
                  onChange={(e) => setfield(e.target.value)}
                  type="text"
                  placeholder="Ketik Nomor Transaksi Disini"
                  className="w-full mr-4 font-Rubik text-[#787878] font-light text-lg hover:outline-none focus:outline-none"
                />
                <button onClick={() => SubmitButton()} className="sm:mt-0 mt-4 h-[50px] w-[130px] bg-[#84db00] rounded-[30px] text-white font-Rubik font-medium text-base hover:bg-[#6EB700] transition duration-300">
                  Telusuri
                </button>
              </div>
            </div>
            <p className="mt-8 sm:mt-4 mb-4 sm:mb-0 px-4 text-center sm:w-[435px] text-white font-Rubik font-normal ">Kamu bisa check status atau lacak proses qurban mu disini, Masukan nomor pesanan lalu klik telusuri.</p>
          </div>
          <div className="sm:flex sm:justify-between mt-20 sm:mt-0 ">
            <img src={Vector_Hero1} className="sm:h-[270px] object-cover" alt="vector1" />
            <img src={Vector_Hero2} className="h-0 lgx:h-[270px] invisible md:visible" alt="vector2" />
          </div>
        </div>
        <div className="min-h-[70px] bg-[#84db00]"></div>
      </div>
    </>
  );
}
