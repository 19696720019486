import React from "react";
import Logo_White from "../images/logo_izi_putih.png";

export default function Navbar() {
  return (
    <>
      <div className=" bg-[#84db00]  py-3 sm:py-3 px-6 lgx:px-16">
        <div className="">
          <img src={Logo_White} className="sm:h-[50px] h-[40px] " alt="logo_izi_putih" />
        </div>
      </div>
    </>
  );
}
