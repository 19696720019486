import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import idM from "moment/locale/id";
import { useParams } from "react-router-dom";
import HistoryStatus from "./HistoryStatus";
import DownloadLaporan from "./DownloadLaporan";
import { chain } from "lodash";

export default function DetailTransaksi(props) {
  const { id } = useParams();
  const url = "https://trackingapi.inisiatif.id/api/v1/tracking/" + props.id;
  const urlDetail = `https://trackingapi.inisiatif.id/api/v1/tracking/${props.id}/event`;
  const [dataTransaksi, setdataTransaksi] = useState({ details: [{}] });
  const [lastEvent, setLastEvent] = useState([]);

  useEffect(() => {
    axios.get(url).then((res) => {
      setdataTransaksi(res.data.meta);
    });
  }, [url]);

  useEffect(() => {
    axios.get(urlDetail).then((res) => {
      const finishEvents = chain(res.data)
        .filter({ type: "CUTTING PLAN" })
        .orderBy("created_at", "desc")
        .uniqBy((obj) => obj.meta.neats_number)
        .orderBy((obj) => obj.meta.neats_number, "desc")
        .value();
      setLastEvent(finishEvents);
    });
  }, [urlDetail]);

  const dateFromat = (param) => {
    if (!param) {
      return "-";
    }
    return moment(param).locale("id", idM).format("DD MMMM YYYY");
  };

  const rupiahFormat = (param) => {
    if (!param) {
      return "-";
    }
    
    if(isNaN(+param)) {
      return param;
    }
    
    const price = new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(param);
    return price;
  };

  return (
    <>
      <div>
        <div>
          <h1 className="font-Rubik font-medium text-[#787878] text-[16px]">Status Qurban </h1>
          <div className="bg-[#82BE41] h-[3px] w-[80px] rounded-[5px] mt-2"></div>
        </div>
        <HistoryStatus id={id} />
      </div>
      <div>
        <div>
          <h1 className="font-Rubik font-medium text-[#787878] text-[16px] mt-4">Data Transaksi </h1>
          <div className="bg-[#82BE41] h-[3px] w-[80px] rounded-[5px] mt-2"></div>
        </div>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-4 mt-8 font-Rubik text-base sm:text-[15px] font-semibold text-[#787878]">
        <div className="text-center">
          <div className="bg-[#F5F5F5] py-1 font-light">
            <h3>Nomor Transaksi</h3>
          </div>
          <h3 className="my-4 max-w-[200px] mx-auto">{dataTransaksi.identification_number}</h3>
        </div>
        <div className=" text-center">
          <div className="bg-[#F5F5F5] py-1 font-light">
            <h3>Nama Donatur</h3>
          </div>
          <h3 className="my-4 max-w-[200px] mx-auto">{dataTransaksi.name}</h3>
        </div>
        <div className=" text-center">
          <div className="bg-[#F5F5F5] py-1 font-light">
            <h3>Tanggal Transaksi</h3>
          </div>
          <h3 className="my-4 max-w-[200px] mx-auto ">{dateFromat(dataTransaksi.payment_date)}</h3>
        </div>
        <div className="text-center">
          <div className="bg-[#F5F5F5] py-1 font-light">
            <h3>Total Pembayaran</h3>
          </div>
          <h3 className="my-4 max-w-[200px] mx-auto ">{rupiahFormat(dataTransaksi.amount)}</h3>
        </div>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-4 mt-0 sm:mt-8 font-Rubik text-base sm:text-[15px] font-semibold text-[#787878]">
        <div className="col-span-1 text-center">
          <div className="bg-[#F5F5F5] py-1 font-light">
            <h3>Produk</h3>
          </div>
          {dataTransaksi.details.map((detail, index) => (
            <h4 key={index + 1} className="my-4 max-w-[400px] mx-auto ">
              {detail.program}
            </h4>
          ))}
        </div>
        <div className=" text-center">
          <div className="bg-[#F5F5F5] py-1 font-light">
            <h3>Jumlah Pembayaran</h3>
          </div>
          {dataTransaksi.details.map((detail, index) => (
            <h3 key={index + 1} className="my-4 max-w-[200px] mx-auto ">
              {rupiahFormat(detail.amount)}
            </h3>
          ))}
        </div>
        <div className=" text-center">
          <div className="bg-[#F5F5F5] py-1 font-light">
            <h3>Nomor Hewan</h3>
          </div>
          {lastEvent.map((nomor) => (
            <h3 key={nomor.id} className="my-4 max-w-[200px] mx-auto ">
              {nomor.meta.neats_number}
            </h3>
          ))}
        </div>
        <div className=" text-center">
          <div className="bg-[#F5F5F5] py-1 font-light">
            <h3>Rencana Pemotongan</h3>
          </div>
          {lastEvent.map((tgl) => (
            <h1 key={tgl.id} className="my-4 max-w-[200px] text-[#82BE41] mx-auto">
              {dateFromat(tgl.meta.cutting_plan_at)}
            </h1>
          ))}
        </div>
      </div>

      <DownloadLaporan id={id} />
      <div>
        <h1 className="font-Rubik font-medium text-[#787878] text-[16px] mt-8 sm:mt-4">Data Pekurban </h1>
        <div className="bg-[#82BE41] h-[3px] w-[80px] rounded-[5px] mt-2"></div>
      </div>
      <div>
        <div className="flex mt-8 font-Rubik text-sm font-normal sm:text-base text-[#787878]">
          <div className="flex flex-wrap gap-2">
            {dataTransaksi.details.map((detail, index) => (
              <div key={index + 1} className="flex flex-wrap gap-2">
                {detail.names?.map((name) => (
                  <h4 key={index + 1} className="py-2 px-8 border-[1.5px] border-[#84db00] bg-[#E6F8CC] rounded-[50px]">
                    {name}
                  </h4>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
