import axios from "axios";
import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import BodySearch from "../Components/BodySearch";
import DataNotFound from "../Components/DataNotFound";
import Footer from "../Components/Footer";
import Hero from "../Components/Hero";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Home() {
  const url = "https://trackingapi.inisiatif.id/api/v1/tracking?number=";
  let query = useQuery();
  let paramValue = query.get("transaksi");
  const scrollToSection = () => window.scrollTo({ top: 700, behavior: "smooth" });

  const [noid, setNoid] = useState("");


  useEffect(() => {
    if(paramValue) {
      handleSearch(paramValue);
      scrollToSection();
    }
  }, [paramValue])


  const handleSearch = (param) => {
    axios.get(url + param).then((res) => {
      if (res.data.length) {
        setNoid(res.data[0].id);
      } else {
        console.log("data tidak ditemukan");
      }
    });
  };

  return (
    <>
      <Hero onSearch={handleSearch}></Hero>
      {!noid ? <DataNotFound /> : <BodySearch noid={noid}></BodySearch>}
      <Footer></Footer>
    </>
  );
}
